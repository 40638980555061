import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { TbZodiacAquarius, TbZodiacAries, TbZodiacCancer, TbZodiacCapricorn, TbZodiacGemini, TbZodiacLeo, TbZodiacLibra, TbZodiacPisces, TbZodiacSagittarius, TbZodiacScorpio, TbZodiacTaurus, TbZodiacVirgo } from 'react-icons/tb';

import './SignSection.css';

const zodiacDetails = {
    Aquarius: { name: 'Aquarius', description: 'Innovative and original, Aquarius is known for their independent nature.' },
    Aries: { name: 'Aries', description: 'Aries is courageous and energetic, always ready for new challenges.' },
    Cancer: { name: 'Cancer', description: 'Cancer is empathetic and caring, deeply connected to their emotions.' },
    Capricorn: { name: 'Capricorn', description: 'Capricorn is disciplined and practical, often driven by their goals.' },
    Gemini: { name: 'Gemini', description: 'Gemini is curious and adaptable, with a talent for communication.' },
    Leo: { name: 'Leo', description: 'Leo is confident and charismatic, always seeking the spotlight.' },
    Libra: { name: 'Libra', description: 'Libra is diplomatic and fair-minded, always seeking balance and harmony.' },
    Pisces: { name: 'Pisces', description: 'Pisces is compassionate and intuitive, often connected to the spiritual world.' },
    Sagittarius: { name: 'Sagittarius', description: 'Sagittarius is adventurous and optimistic, with a love for exploration.' },
    Scorpio: { name: 'Scorpio', description: 'Scorpio is intense and passionate, with a deep emotional core.' },
    Taurus: { name: 'Taurus', description: 'Taurus is reliable and patient, with a strong connection to the material world.' },
    Virgo: { name: 'Virgo', description: 'Virgo is analytical and meticulous, always striving for perfection.' },
};

const SignSection = ({ selectedZodiac, setSelectedZodiac }) => {
    const renderPopover = zodiac => (
        <Popover id={`popover-${zodiac}`}>
            <Popover.Header as="h3">{zodiacDetails[zodiac].name}</Popover.Header>
            <Popover.Body>{zodiacDetails[zodiac].description}</Popover.Body>
        </Popover>
    );

    const renderIcon = zodiac => {
        const IconComponent = {
            Aquarius: TbZodiacAquarius,
            Aries: TbZodiacAries,
            Cancer: TbZodiacCancer,
            Capricorn: TbZodiacCapricorn,
            Gemini: TbZodiacGemini,
            Leo: TbZodiacLeo,
            Libra: TbZodiacLibra,
            Pisces: TbZodiacPisces,
            Sagittarius: TbZodiacSagittarius,
            Scorpio: TbZodiacScorpio,
            Taurus: TbZodiacTaurus,
            Virgo: TbZodiacVirgo,
        }[zodiac];

        return (
            <OverlayTrigger key={zodiac} placement="top" overlay={renderPopover(zodiac)} flip={false}>
                <span>
                    <IconComponent size={36} strokeWidth={1} className={`zodiac-icon ${selectedZodiac === zodiac ? 'selected' : ''}`} onClick={() => setSelectedZodiac(zodiac)} />
                </span>
            </OverlayTrigger>
        );
    };

    return <div className="grid-container">{Object.keys(zodiacDetails).map(renderIcon)}</div>;
};

export default SignSection;
