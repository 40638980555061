import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

import './HomeView.css';

const HomeView = ({ openOffcanvas }) => {
    return (
        <div>
            <div className="hero-section text-center">
                <Container>
                    <h1>Welcome to Pickford Labs</h1>
                    <p>Explore our experiments and see how we&apos;re pushing the boundaries of creativity and technology.</p>
                    <Button variant="primary" onClick={openOffcanvas}>
                        Explore
                    </Button>
                </Container>
            </div>

            <Container className="explanation-section my-5">
                <Row>
                    <Col md={4} className="text-center">
                        <h3>Future of Storytelling</h3>
                        <p>At Pickford, we believe the world is on the verge of the next generation of storytelling and are excited to push creativity in many directions.</p>
                    </Col>
                    <Col md={4} className="text-center">
                        <h3>Rapid Exploration</h3>
                        <p>New technologies require quick iteration and lots of user feedback. Pickford Labs is designed to be a safe space to try as many ideas as possible!</p>
                    </Col>
                    <Col md={4} className="text-center">
                        <h3>Open Research</h3>
                        <p>Our goal is to build in the open. How we experiment should be treated the same way. Everything that shows up here directly drives development of Pickford Studio.</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default HomeView;
