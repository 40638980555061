import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, ProgressBar, Modal, Form } from 'react-bootstrap';
import { AiFillInfoCircle } from 'react-icons/ai';

import SignSection from './SignSection';
import ScriptSection from './ScriptSection';
import { generateScript } from './backendInterface';

import './FirstDateView.css';

const FirstDateView = () => {
    const [charOneName, setCharOneName] = useState('');
    const [charTwoName, setCharTwoName] = useState('');

    const [selectedCharOneZodiac, setSelectedCharOneZodiac] = useState(null);
    const [selectedCharTwoZodiac, setSelectedCharTwoZodiac] = useState(null);

    const [activeScene, setActiveScene] = useState(0);
    const [script, setScript] = useState([]);
    const [progress, setProgress] = useState(0);

    const [showIntroModal, setShowIntroModal] = useState(true); // For the introductory modal
    const [showEditModal, setShowEditModal] = useState(false); // For the edit prompts modal
    const [isHovered, setIsHovered] = useState(false);

    const handleCloseIntroModal = () => setShowIntroModal(false);
    const handleShowEditModal = () => setShowEditModal(true);
    const handleCloseEditModal = () => setShowEditModal(false);

    const promptTitles = ['System Prompt', 'Character Prompts', 'Scene 1 Prompt', 'Scene 2 Prompt', 'Scene 3 Prompt'];
    const [scenePrompts, setScenePrompts] = useState([
        'You are the director and writer of a reality TV show where 2 individuals are going on a first date. Write a Hollywood style script of this date.',
        `The characters are ${charOneName}, who is a ${selectedCharOneZodiac} and ${charTwoName}, who is a ${selectedCharTwoZodiac}.`,
        'Create the first scene where the characters introduce themselves. Their zodiac signs and how they match up should be an important point of topic.',
        'The second scene should be some hilarious banter and getting to know each other. This could go good or bad, depending on their zodiac alignments.',
        'The last scene culminated in the individuals deciding if there will be a second date. Randomly decide between this being a `YES`, `NO`, or an awkward `WE SHOULD SLEEP ON IT`. If the date went particularly bad, one should "go to the bathroom" and sneak out.',
    ]);

    useEffect(() => {
        setShowIntroModal(true);
    }, []);

    useEffect(() => {
        const content = `The characters are ${charOneName}, who is a ${selectedCharOneZodiac} and ${charTwoName}, who is a ${selectedCharTwoZodiac}.`;
        handleInputChange(1, content);
    }, [charOneName, selectedCharOneZodiac, charTwoName, selectedCharTwoZodiac]);

    const generateDate = async () => {
        setScript([]);
        setActiveScene(0);
        setProgress(3);
        await generateScript(scenePrompts, setScript, setProgress);
    };

    const handleInputChange = (index, content) => {
        const newEditableMessages = [...scenePrompts];
        newEditableMessages[index] = content;
        setScenePrompts(newEditableMessages);
    };

    useEffect(() => {
        const handleKeyDown = event => {
            // Check if CMD (or CTRL for Windows) + E is pressed
            if ((event.metaKey || event.ctrlKey) && event.key === 'e') {
                event.preventDefault(); // Prevent default behavior (like focusing browser search)
                handleShowEditModal(); // Show the modal
            }
        };

        // Add the event listener
        window.addEventListener('keydown', handleKeyDown);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <Container fluid>
            <Row style={{ height: '75vh' }}>
                <Col className="border p-4 shadow rounded m-2">
                    <Row className="justify-content-center ">
                        <Card style={{ width: '40%', padding: '0px', margin: '20px' }}>
                            <Card.Header>
                                <input type="text" value={charOneName} onChange={e => setCharOneName(e.target.value)} className="character-name-input" placeholder="Enter name..." />
                            </Card.Header>
                            <Card.Img variant="top" src="char1.png" className="square-img" />
                            <Card.Body>
                                <SignSection selectedZodiac={selectedCharOneZodiac} setSelectedZodiac={setSelectedCharOneZodiac} />
                            </Card.Body>
                        </Card>
                        <Card style={{ width: '40%', padding: '0px', margin: '20px' }}>
                            <Card.Header>
                                <input type="text" value={charTwoName} onChange={e => setCharTwoName(e.target.value)} className="character-name-input" placeholder="Enter name..." />
                            </Card.Header>
                            <Card.Img variant="top" src="char2.webp" className="square-img" />
                            <Card.Body>
                                <SignSection selectedZodiac={selectedCharTwoZodiac} setSelectedZodiac={setSelectedCharTwoZodiac} />
                            </Card.Body>
                        </Card>
                    </Row>
                    <Row>
                        <Button onClick={generateDate}>See Their First Date</Button>
                    </Row>
                    <Row className="mt-3">{progress > 0 && progress < 100 && <ProgressBar now={progress} variant="success" animated />}</Row>
                </Col>
                <Col md={6} className="border p-4 shadow rounded m-2">
                    <ScriptSection script={script} activeScene={activeScene} setActiveScene={setActiveScene} />
                </Col>
            </Row>

            {/* Introductory Modal */}
            <Modal show={showIntroModal} onHide={handleCloseIntroModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Welcome to the First Date Experiment!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Here, you can create two characters, assign them zodiac signs, and see how their first date plays out based on their personalities and zodiac compatibility.</p>
                    <p>Start by entering the names of the characters, choosing their zodiac signs, and then click &quot;See Their First Date&quot; to generate a script.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseIntroModal}>
                        Got it!
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Edit Prompts Modal */}
            <Modal show={showEditModal} onHide={handleCloseEditModal} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Prompts</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {scenePrompts.map((message, index) => (
                        <Form.Group key={index} controlId={`message-${index}`} className="mt-2">
                            <Form.Label className="fw-bold">{promptTitles[index]}</Form.Label>
                            {index === 1 && (
                                <>
                                    <AiFillInfoCircle
                                        color="grey"
                                        size={20}
                                        style={{ marginLeft: '8px', cursor: 'pointer' }}
                                        onMouseEnter={() => setIsHovered(true)} // Show message on hover
                                        onMouseLeave={() => setIsHovered(false)} // Hide message when not hovered
                                    />
                                    {isHovered && <span style={{ color: 'red', marginLeft: '8px' }}>This is populated via the character cards</span>}
                                </>
                            )}
                            <Form.Control as="textarea" rows={3} value={message} onChange={e => handleInputChange(index, e.target.value)} disabled={index === 1} />
                        </Form.Group>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseEditModal}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default FirstDateView;
