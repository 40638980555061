import React from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import { FaLinkedin, FaYoutube, FaMedium, FaGlobe } from 'react-icons/fa';

const Footer = () => {
    return (
        <footer className="py-3">
            <Container>
                <Row>
                    <Col>
                        <p className="footer-content">&copy; 2024 Pickford Technologies, Inc. All rights reserved.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Nav className="justify-content-center">
                            <Nav.Link href="https://www.linkedin.com/company/pickford-ai" target="_blank">
                                <FaLinkedin size={20} />
                            </Nav.Link>
                            <Nav.Link href="https://medium.com/@pickfordai" target="_blank">
                                <FaMedium size={20} />
                            </Nav.Link>
                            <Nav.Link href="https://www.youtube.com/@PickfordAI" target="_blank">
                                <FaYoutube size={20} />
                            </Nav.Link>
                            <Nav.Link href="https://pickford.ai/" target="_blank">
                                <FaGlobe size={20} />
                            </Nav.Link>
                        </Nav>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
