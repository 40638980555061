import React from 'react';
import { Tabs, Tab, Dropdown, DropdownButton } from 'react-bootstrap';
import { TbShare2 } from 'react-icons/tb';
import ReactMarkdown from 'react-markdown';

import './ScriptSection.css';

const ScriptSection = ({ script, activeScene, setActiveScene }) => {
    const handleDownload = () => {
        const combinedScript = script.join('\n\n');
        const blob = new Blob([combinedScript], { type: 'text/markdown;charset=utf-8' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'script.md'; // Changed file extension to .md
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="script-section">
            <Tabs activeKey={activeScene} onSelect={k => setActiveScene(k)} className="script-tabs">
                {script.map((scene, index) => (
                    <Tab eventKey={index} key={index} title={<>Scene {index + 1}</>}>
                        <div className="markdown-container">
                            <ReactMarkdown>{scene}</ReactMarkdown>
                        </div>
                    </Tab>
                ))}
            </Tabs>
            {script.length === 3 && (
                <DropdownButton id="dropdown-basic-button" title={<TbShare2 className="share-icon" />} align="end" variant="light" className="dropdown-share">
                    <Dropdown.Header>Share Script</Dropdown.Header>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={() => handleDownload()}>Download</Dropdown.Item>
                </DropdownButton>
            )}
        </div>
    );
};

export default ScriptSection;
