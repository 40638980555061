import React, { useState } from 'react';
import { Navbar, Container, Offcanvas, Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

const Header = ({ experiments, toggleRef }) => {
    const location = useLocation();
    const [showOffcanvas, setShowOffcanvas] = useState(false);

    const handleCloseOffcanvas = () => setShowOffcanvas(false);
    const handleShowOffcanvas = () => setShowOffcanvas(true);

    return (
        <Navbar sticky="top" expand={false} bg="primary" data-bs-theme="dark">
            <Container fluid className="d-flex align-items-center">
                <div className="d-flex align-items-center">
                    <Navbar.Toggle aria-controls="offcanvasNavbar-expand" ref={toggleRef} onClick={handleShowOffcanvas} />
                    <Navbar.Brand as={Link} to="/" className="ms-2">
                        Pickford Labs
                    </Navbar.Brand>
                </div>
                <Navbar.Offcanvas id="offcanvasNavbar-expand" aria-labelledby="offcanvasNavbarLabel-expand" placement="start" show={showOffcanvas} onHide={handleCloseOffcanvas}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title id="offcanvasNavbarLabel-expand">Experiments</Offcanvas.Title>
                    </Offcanvas.Header>

                    <Offcanvas.Body>
                        <div className="d-grid gap-2">
                            {experiments.map(tab => {
                                const path = `/${tab.replace(/\s+/g, '-').toLowerCase()}`;
                                const isActive = location.pathname === path;

                                return (
                                    <Button key={tab} as={Link} to={path} variant="outline-dark" className={isActive ? 'active' : ''} onClick={handleCloseOffcanvas}>
                                        {tab}
                                    </Button>
                                );
                            })}
                        </div>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    );
};

export default Header;
