import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Header from './components/Labs/Header';
import Footer from './components/Labs/Footer';
import HomeView from './components/Labs/HomeView';
import FirstDateView from './components/FirstDate/FirstDateView';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
    const experiments = ['First Date'];

    const toggleRef = useRef(null);

    const openOffcanvas = () => {
        if (toggleRef.current) {
            toggleRef.current.click(); // Trigger the Navbar.Toggle button
        }
    };

    return (
        <Router>
            <div className="App">
                <Helmet>
                    <script type="text/javascript">
                        {`
                            (function(c,l,a,r,i,t,y){
                                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                            })(window, document, "clarity", "script", "nx41zwauc0");
                        `}
                    </script>
                </Helmet>
                <Header experiments={experiments} toggleRef={toggleRef} />
                <div className="content">
                    <Routes>
                        <Route path="*" element={<HomeView openOffcanvas={openOffcanvas} />} />
                        <Route path="first-date" element={<FirstDateView />} />
                    </Routes>
                </div>
                <Footer />
            </div>
        </Router>
    );
}

export default App;
