const ENDPOINT_URL = `${process.env.REACT_APP_MAIN_API_URL}/generate_text`;

const generateScene = async messages => {
    try {
        const response = await fetch(ENDPOINT_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({ messages }),
        });

        if (!response.ok) {
            const error = await response.json();
            console.error('Error generating text:', error.detail);
            return;
        }

        const data = await response.json();
        return data.content;
    } catch (error) {
        console.log(error);
    }
};

export const generateScript = async (prompts, setScript, setProgress) => {
    const totalScenes = 3;
    const messages = [
        { role: 'system', content: prompts[0] },
        { role: 'user', content: prompts[1] },
    ];

    // Get the content for the first scene
    messages.push({ role: 'user', content: prompts[2] });
    const sceneOneContent = await generateScene(messages);
    setScript(prevScript => [...prevScript, sceneOneContent]);
    setProgress(prevProgress => prevProgress + 100 / totalScenes);
    messages.push({ role: 'assistant', content: sceneOneContent });

    // Get the content for the second scene
    messages.push({ role: 'user', content: prompts[3] });
    const sceneTwoContent = await generateScene(messages);
    setScript(prevScript => [...prevScript, sceneTwoContent]);
    setProgress(prevProgress => prevProgress + 100 / totalScenes);
    messages.push({ role: 'assistant', content: sceneTwoContent });

    // Get the content for the third scene
    messages.push({ role: 'user', content: prompts[4] });
    const sceneThreeContent = await generateScene(messages);
    setScript(prevScript => [...prevScript, sceneThreeContent]);
    setProgress(100);

    // Reset progress after a short delay
    setTimeout(() => setProgress(0), 50000);
};
